import { Component } from '@angular/core';
import { AuthService } from 'src/app/core/auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: []
})
export class AppHeaderComponent {
  isAuthenticated = false;
  user: any = {};
  constructor(public authService: AuthService) {
    authService.isLoggedin.subscribe((user: any) => {
      if (user) {
        this.user = user;
        this.isAuthenticated = true
      }
    })
  }
}
