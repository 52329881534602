import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs/Observable";
import { map } from "rxjs/operators";

@Injectable()
export class ApplicationService {

  constructor(private http: HttpClient) {

  }
  /*DYNAMIC LAYOUTS*/
  // post(module: string, type: string, body: any = {}, query: string = "") {
  //   return this.http.get<Array<any>>(`/api/page/${module}/${type}`, {
  //     params: new HttpParams()
  //       .set('query', query)
  //   }).pipe(
  //     map(res => res["payload"])
  //   );
  // }
  host = "https://advertisment-kiosks.emtrendz.com";
  post(endpoint: string, body: any = {}) {
    return this.http.post<Array<any>>(`/${endpoint}`, body).pipe(
      map(res => res["payload"])
    ).subscribe((d) => {
    });
  }
}