import { Routes } from '@angular/router';

import { LoginComponent } from './login/login.component';
import { UserComponent } from './user/user.component';
import { RegisterComponent } from './register/register.component';
import { UserResolver } from './user/user.resolver';
import { AuthGuard } from './core/auth.guard';

// import { ListComponent as DevicesListComponent } from './pages/devices/list/list.component';
// import { RegisterComponent as DevicesRegisterComponent } from './pages/devices/register/register.component';

// import { ListComponent as ContentListComponent } from './pages/content/list/list.component';
// import { UploadComponent as ContentUploadComponent } from './pages/content/upload/upload.component';
import { FullComponent } from './layouts/full/full.component';
import { ErrorComponent } from './error/error.component';

export const rootRouterConfig: Routes = [
{
  path: '',
    component: FullComponent,
    children: [
      {
        path: '',
        component: LoginComponent,
        pathMatch: 'full'
      },
      {
        path: 'error',
        component: ErrorComponent
      },
      {
        path: 'pages',
        loadChildren: './pages/pages.module#PagesModule', 
        canActivate: [AuthGuard]
      },
    ]
}
  // { path: '', redirectTo: 'login', pathMatch: 'full' },
  // { path: 'login', component: LoginComponent, canActivate: [AuthGuard] },
  // { path: 'register', component: RegisterComponent, canActivate: [AuthGuard] },
  // { path: 'user', component: UserComponent, resolve: { data: UserResolver } },
  // { path: 'devices', component: DevicesListComponent, canActivate: [AuthGuard] },
  // { path: 'register-device', component: DevicesRegisterComponent, canActivate: [AuthGuard] },
  // { path: 'content', component: ContentListComponent, canActivate: [AuthGuard] },
  // { path: 'content-upload', component: ContentUploadComponent, canActivate: [AuthGuard] },
];
